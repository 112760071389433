import React from "react";

import { Input } from "../../../../components/forms";

import * as styles from "./styles.module.scss";

interface PaymentDetailsProps {}

interface PaymentDetailsState {
  paymentMethod: string;
  cardHolder: string;
  cardNumber: string;
  expirationDate: string;
  cvv: string;
}
export default class PaymentDetails extends React.Component<
  PaymentDetailsProps,
  PaymentDetailsState
> {
  constructor(props) {
    super(props);

    this.state = {
      paymentMethod: "",
      cardHolder: "",
      cardNumber: "",
      expirationDate: "",
      cvv: "",
    };
  }

  updateForm = () => {};

  render() {
    return (
      <div className={styles.paymentDetails}>
        <Input
          className={styles.fullInput}
          label="Cardholder"
          type="text"
          value={this.state.cardHolder}
          onChange={(e) => this.setState({ cardHolder: e.target.value })}
        />
        <Input
          className={styles.fullInput}
          label="Cardnumber"
          type="text"
          value={this.state.cardNumber}
          onChange={(e) => this.setState({ cardNumber: e.target.value })}
        />
        <Input
          className={styles.halfInput}
          label="Expiration date"
          type="text"
          value={this.state.expirationDate}
          onChange={(e) => this.setState({ expirationDate: e.target.value })}
        />
        <Input
          className={styles.halfInput}
          label="cvv"
          type="text"
          value={this.state.cvv}
          onChange={(e) => this.setState({ cvv: e.target.value })}
        />
      </div>
    );
  }
}
